import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Louisa’s finger pressed the doorbell for the fifth time as she shivered against her damp clothes. She had dragged herself through the horizontal rains of April with a broken umbrella and jeans drenched with water. Water dripped off of her raincoat and pooled around her dirty wellingtons. She hadn’t come all this way to check up on Sarah just to catch a cold. She snorted at the idea - The mummy hunter becomes a mummy - a Japanese proverb that seemed very fitting of the idea.`}</p>
    <p>{`She jammed her finger into the doorbell again before she resorted to impatiently rapping on the door. Sarah was not responding to any of her texts or calls. She could be dead for all Louisa knew. Ever since Sarah started freelancing as an animator, she had become shut-in, excusing her lack of communication to everyone by saying that she had a heavy workload and was not able to hang out. That would’ve been fine if she hadn’t stopped coming out of her home for weeks on end. The only thing stopping Louisa from filing a missing person report was a little green dot next to Sarah’s Instagram profile that signaled that she was active.`}</p>
    <p>{`After abusing the poor door for another five minutes, she made out the soft patters of a pair of feet approaching the entrance.`}</p>
    <p>{`The doors opened to reveal a Sarah she hadn’t seen since college. Her messy hair fell over her shoulders ungracefully, much like that of a plate of spaghetti tipped into a garbage can. Her clothes looked like she hadn’t changed them in a week. The lack of odor surprised her, but that was nothing compared to the dark circles that framed her eyes. Louisa’s arms reached out to Sarah’s shoulders but were shrugged off.`}</p>
    <p>{`“Come in,” Sarah rasped and moved to make room for her to enter.`}</p>
    <p>{`Five years ago, when Sarah was still working at her old, sustainable job, she had excitedly dragged Louisa all over the city to help her buy things for her newly-bought flat. The flower vase Louisa gifted to her to celebrate the occasion had contained flowers when they partied that night over Thai takeout. Now, the vase was sitting on a windowsill collecting dust. A few other objects sat on different surfaces, and after taking a closer look, Louisa recognized them as different objects from the past. The once vibrantly colored birthday girl mug was on the kitchen counter, now chipped and faded from years of use. Family pictures hung on the walls, smiling faces covered in layers of dust and grime. Books were all over the coffee table, and a chair was buried under a pile of clothes.`}</p>
    <p>{`“So,” Sarah’s voice startled Louisa out of her thoughts. “What’s up?”`}</p>
    <p>{`“I just came to see you because I haven’t seen you in a long time,” she said, looking up to Sarah pouring a cup of something brown for her. “What were you doing?”`}</p>
    <p>{`“Oh, you know, just working.”`}</p>
    <p>{`What? Working? Louisa couldn’t believe what she was hearing. Working?`}</p>
    <p>{`“You haven’t spoken to anyone in months! You were working the entire time?” She cringed at the tone of her voice, but that didn’t matter. Her friend was acting like incredibly stupid.`}</p>
    <p>{`“Look, it wasn’t that bad, I went outside at least once a week, it’s not like I was working the entire time,” Sarah replied, setting a mug in front of Louisa`}</p>
    <p>{`“Once a week? Really?”`}</p>
    <p>{`“Hey, it’s better than nothing.”`}</p>
    <p>{`“You haven’t responded to my texts since February, and you’re telling me that you’ve been working the entire time?”`}</p>
    <p>{`Sarah set her mug down with a thud. “Look, I’ve been busy with a new client, okay? I’m fine, don’t worry about me.”`}</p>
    <p>{`Louisa was tempted to grab her by the arms and shake some sense into her.`}</p>
    <p>{`“I absolutely will worry, you’re being ridiculous!”`}</p>
    <p>{`“No, I am not!” Sarah raised her voice. “It’s my`}<br parentName="p"></br>{`
`}{`career, and working isn’t ridiculous.”`}</p>
    <p>{`“Ah, yes! You’re right, working for months on end without taking a single break is perfectly fine and won’t do any damage. You’re being a moron!”`}<br parentName="p"></br>{`
`}{`“Did you visit me just to insult me and my career?”`}</p>
    <p>{`“No, I came all the way here to check up on you, but now I’m wondering if you need some sense knocked into you.”`}</p>
    <p>{`“I’ve been working. You don’t have to worry about anything because I’m perfectly fine!”`}</p>
    <p>{`“You need to take a break.”`}</p>
    <p>{`“You are not in a position to tell me what to do!”`}</p>
    <p>{`Louisa slammed her mug down, internally groaning at the loud thud it made. “Any sane person can see that you need to take a break!”`}</p>
    <p>{`“Are you calling me insane?” Sarah snarled, narrowing her eyes.`}</p>
    <p>{`“Yes! I am! Take a damn break!” Louisa yelled. “Your devotion to your job is getting out of hand! It’s unhealthy, it’s hurting you, take a break or get some help!”`}</p>
    <p>{`Sarah’s chair tumbled backward unceremoniously as she abruptly stood up. Louisa got up, ready to argue with her.`}</p>
    <p>{`“My job keeps a roof over my head and food on my table, you have NO right to judge!” Sarah shouted, angrily making her way over to Louisa.`}</p>
    <p>{`“It’s keeping you alive, but your obsession with it is ripping you apart. Take a break, damn it!” Louisa stepped towards Sarah.`}</p>
    <p>{`“Shut UP!”`}</p>
    <p>{`“Take a-” Louisa started, but was interrupted by an enraged Sarah.`}</p>
    <p>{`“If you’re going to say things like that, get OUT!”`}</p>
    <p>{`“No, I won’t leave until you agree to take a break.”`}</p>
    <p>{`“Why are you acting like YOU know my life better than ME?”`}</p>
    <p>{`“Because you’re being a DEMENTED FOOL who can’t TAKE CARE OF HERSELF!”`}</p>
    <p>{`Louisa immediately regretted saying that as Sarah’s face contorted into a menacing glare that made her want to recoil.`}</p>
    <p>{`“SHUT UP. SHUT UP. SHUT UP!” Sarah screamed, making Louisa flinch. “GET OUT OF MY HOUSE!”`}</p>
    <p>{`“I’m sorry, I-”`}</p>
    <p>{`“GET,” Sarah seized Louisa’s arm, “OUT.”`}</p>
    <p>{`Louisa struggled as Sarah dragged her to the entrance of the apartment. Sarah’s grip on her arm was painful, and Louisa could feel her arms bruising. She gritted her teeth as the grip tightened.`}</p>
    <p>{`“Look, Sarah, I’m sorry, I shouldn’t have said that,” She started. She genuinely wanted to apologize, seeing that she had done more damage than good.`}</p>
    <p>{`“I don’t care about your opinions anymore, I want you OUT.” Sarah let go of her arm and violently shoved her to the door.`}</p>
    <p>{`“Sarah-”`}</p>
    <p>{`Louisa’s words were disrupted as a glass object collided with the door behind her. She stared in horror at the shattered pieces, realizing that they belonged to the flower vase she had gifted to Sarah. She reached for the door handle and twisted it with her shaking hands, relieved to see that the door was not locked.`}</p>
    <p>{`She turned around one last time but was met with an infuriated Sarah forcing her out the door.`}</p>
    <p>{`The door slammed shut.`}</p>
    <p>{`The muffled sound of glass crunching and colorful cursing soon faded to silence.`}</p>
    <p>{`What have I done?`}</p>
    <p>{`The question lingered on her mind as she made her way home. She wanted to cry. Sarah was probably working again, maybe even harder than ever. She would probably work herself to death this time. If only Louisa had used a different approach, if only she hadn’t been so accusatory with her words, if only she hadn’t called her a demented fool-`}</p>
    <p>{`She went to bed feeling low-spirited, waking up the next day with a cold, a sour reminder of the day before.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      